import { CurrencyAmount, ETHER, JSBI } from '@kuramaswap/sdk'
import { MIN_ETH } from '../eth_constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
    if (!currencyAmount) return undefined
    if (currencyAmount.currency === ETHER) {
        if (JSBI.greaterThan(currencyAmount.raw, MIN_ETH)) {
            return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_ETH))
        } else {
            return CurrencyAmount.ether(JSBI.BigInt(0))
        }
    }
    return currencyAmount
}
