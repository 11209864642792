import { Percent } from '@kuramaswap/sdk'
import React from 'react'
import { ONE_BIPS } from '../../eth_constants'
import { warningSeverity } from '../../utils/prices'

const SEVERITY = {
    0: 'text-green',
    1: 'text-high-emphesis',
    2: 'text-yellow',
    3: 'text-red',
    4: 'text-red'
}

export default function FormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
    return (
        <div className={`text-sm swap_detail_primary`}>
            {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
        </div>
    )
}
