import { Currency, ETHER, WETH, currencyEquals } from '@kuramaswap/sdk'
import { useMemo } from 'react'
import { tryParseAmount } from '../eth_state/swap/hooks'
import { useTransactionAdder } from '../eth_state/transactions/hooks'
import { useCurrencyBalance } from '../eth_state/wallet/hooks'
import { useActiveWeb3React } from './index'
import { useWETHContract } from './useContract'

export enum WrapType {
    NOT_APPLICABLE,
    WRAP,
    UNWRAP
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
    inputCurrency: Currency | undefined,
    outputCurrency: Currency | undefined,
    typedValue: string | undefined
): { wrapType: WrapType; execute?: undefined | (() => Promise<void>); inputError?: string } {
    const { chainId, account } = useActiveWeb3React()
    const wethContract = useWETHContract()
    const balance = useCurrencyBalance(account ?? undefined, inputCurrency)
    // we can always parse the amount typed as the input currency, since wrapping is 1:1
    const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue])
    const addTransaction = useTransactionAdder()

    return useMemo(() => {
        if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE

        const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

        if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
            return {
                wrapType: WrapType.WRAP,
                execute:
                    sufficientBalance && inputAmount
                        ? async () => {
                              try {
                                  const txReceipt = await wethContract.deposit({
                                      value: `0x${inputAmount.raw.toString(16)}`
                                  })
                                  addTransaction(txReceipt, {
                                      summary: `Wrap ${inputAmount.toSignificant(6)} ${Currency.getNativeCurrencySymbol(
                                          chainId
                                      )} to W${Currency.getNativeCurrencySymbol(chainId)}`
                                  })
                              } catch (error) {
                                  console.error('Could not deposit', error)
                              }
                          }
                        : undefined,
                inputError: sufficientBalance
                    ? undefined
                    : `Insufficient ${Currency.getNativeCurrencySymbol(chainId)} balance`
            }
        } else if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
            return {
                wrapType: WrapType.UNWRAP,
                execute:
                    sufficientBalance && inputAmount
                        ? async () => {
                              try {
                                  const txReceipt = await wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`)
                                  addTransaction(txReceipt, {
                                      summary: `Unwrap ${inputAmount.toSignificant(
                                          6
                                      )} W${Currency.getNativeCurrencySymbol(
                                          chainId
                                      )} to ${Currency.getNativeCurrencySymbol(chainId)}`
                                  })
                              } catch (error) {
                                  console.error('Could not withdraw', error)
                              }
                          }
                        : undefined,
                inputError: sufficientBalance ? undefined : 'Insufficient WETH balance'
            }
        } else {
            return NOT_APPLICABLE
        }
    }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction])
}
