import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../theme'

const InfoCard = styled.button<{ active?: boolean }>`
    //background-color: ${({ theme, active }) => (active ? theme.bg3 : theme.bg2)};
    padding: 1rem;
    outline: none;
    // border: 1px solid red;
    border-radius: 16px;
    width: 100% !important;
    &:focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.primary1};
    }
    // border-color: ${({ theme, active }) => (active ? 'transparent' : theme.bg3)};
`

const OptionCard = styled(InfoCard as any)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
`

const OptionCardLeft = styled.div`
    ${({ theme }) => theme.flexColumnNoWrap};
    justify-content: center;
    height: 100%;
`

const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
    margin-top: 0;
    height: 48px;
    padding: 0 24px;
    background-color: #eff4f5;
    box-shadow: none;
    // margin-bottom: 8px;
    &:hover {
        opacity: 0.7;
    }
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

const GreenCircle = styled.div`
    ${({ theme }) => theme.flexRowNoWrap}
    justify-content: center;
    align-items: center;

    &:first-child {
        height: 8px;
        width: 8px;
        margin-right: 8px;
        background-color: ${({ theme }) => theme.green1};
        border-radius: 50%;
    }
`

const CircleWrapper = styled.div`
    color: ${({ theme }) => theme.green1};
    display: flex;
    justify-content: center;
    align-items: center;
`

const HeaderText = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    color: #04bbfb;
    font-size: 1rem;
    font-weight: 900;
`

const SubHeader = styled.div`
    color: ${({ theme }) => theme.text1};
    margin-top: 10px;
    font-size: 12px;
`

const IconWrapper = styled.div<{ size?: number | null }>`
    ${({ theme }) => theme.flexColumnNoWrap};
    align-items: center;
    justify-content: center;
    & > img,
    span {
        height: ${({ size }) => (size ? size + 'px' : '24px')};
        width: ${({ size }) => (size ? size + 'px' : '24px')};
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

let newIconLink = ''
// {icon[0] === '.' ? (newIconLink = icon[0].replace('.', '') + icon) : (newIconLink = icon)}

export default function Option({
    link = null,
    clickable = true,
    size,
    onClick = null,
    color,
    header,
    subheader = null,
    icon,
    active = false,
    id
}: {
    link?: string | null
    clickable?: boolean
    size?: number | null
    onClick?: null | (() => void)
    color: string
    header: React.ReactNode
    subheader: React.ReactNode | null
    icon: string
    active?: boolean
    id: string
}) {
    if (icon[0] === '.') {
        newIconLink = icon[0].replace('.', '') + icon
    } else {
        newIconLink = icon
    }
    const content = (
        <OptionCardClickable id={id} onClick={onClick} clickable={clickable && !active} active={active}>
            <OptionCardLeft>
                <HeaderText color={color}>
                    {active ? (
                        <CircleWrapper>
                            <GreenCircle>
                                <div />
                            </GreenCircle>
                        </CircleWrapper>
                    ) : (
                        ''
                    )}
                    {header}
                </HeaderText>
                {subheader && <SubHeader>{subheader}</SubHeader>}
            </OptionCardLeft>
            <IconWrapper size={size}>
                {/* {console.log(icon)} */}
                <img src={newIconLink} alt={'Icon'} />
                {/* {console.log('New', newIconLink)} */}
            </IconWrapper>
        </OptionCardClickable>
    )
    if (link) {
        return <ExternalLink href={link}>{content}</ExternalLink>
    }

    return content
}
